.statistics-page--container{
    padding-top: 10px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    overflow-x: hidden;
}
p > span {
    color: rgb(156, 42, 38);
}
.statistics-title--holder{
    display: grid;
    width: 300px;
    grid-template-columns: 300px;
    grid-template-rows: 70px 1fr;
    text-align: center;
    margin: 10px auto;
    padding-top: 20px;
}
.statistics-title--logo{
    background-image: url('../assets/logo-no-bg.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;    
    margin-top: 0;
}
.notice-container{
    box-sizing: border-box;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 0 15px 0 15px;
    text-align: center;
}
.statistics-content--holder{
    padding: 0 20px 40px 20px;
}
.statistics-content--in-progress{
    width: 100%;
    text-align: center;
}
.daily-chart{
    touch-action: pan-y;
}
@media (min-width: 700px){
    .statistics-page--container{
        padding-top: 50px;
    }    
    .statistics-title--holder{
        display: grid;
        grid-template-columns: 70px 300px;
        grid-template-rows: 1fr;
        width: 400px;
        margin-top: 0;
    }
    .statistics-title--logo{   
        margin-top: -45px;
    }
    .notice-container{
        padding: 0 50px 0 50px;
    }
}